// Todo: Check isMobile and change semantic. Seems this should be isNotMobile or something another

window.isMobile = () => window.innerWidth >= 768;

window.setToStore = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
    return value;
};

window.getFromStore = (key, defaultValue) =>
    localStorage.getItem(key) == null
        ? defaultValue
        : JSON.parse(localStorage.getItem(key));

window.menuState = {
    sidebar: !window.isMobile() ? true : window.getFromStore("sidebar", true),
    isMobileBreakpoint: window.isMobile(),
    isDesktopBreakpoint: false,
};

let menuStateContainer = document.getElementById("menuStateContainer");
let prevWidth = window.width;
let observer = new ResizeObserver(function (entries) {
    const width = entries[0].borderBoxSize?.[0].inlineSize;

    if (
        menuStateContainer &&
        typeof width === "number" &&
        width !== prevWidth
    ) {
        prevWidth = width;

        window.isMobile()
            ? (window.menuState.isMobileBreakpoint = true)
            : (window.menuState.isDesktopBreakpoint = true);

        if (!window.isMobile() && window.menuState.isMobileBreakpoint) {
            window.menuState.isMobileBreakpoint = false;

            menuStateContainer._x_dataStack[0].sidebar =
                !menuStateContainer._x_dataStack[0].sidebar;
        }

        if (window.isMobile() && window.menuState.isDesktopBreakpoint) {
            window.menuState.isDesktopBreakpoint = false;

            menuStateContainer._x_dataStack[0].sidebar =
                !menuStateContainer._x_dataStack[0].sidebar;
        }
    }
});
observer.observe(window.document.body);
